import { COLORS, REGEXPS, TYPOGRAPHY } from '@/constants';
import { selectors } from '@/store/ducks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { CommunicationMethod, ErrorTexts } from '@/types';
import { Button, Checkbox, InputWithLabel } from '@/ui/components';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { isEmptyObject } from '@/utils/isEmptyObject';
import { getChangedValuesFromObjects } from '@/utils/getChangedValuesFromObjects';
import { updateUserDataThunk } from '@/store/ducks/auth/thunks';

interface FormData {
  firstName: string;
  lastName: string;
  middleName: string;
  mobileNumber: string;
  birthdate: string;
  snils: string;
  email: string;
  workNumber: string;
  address: string;
  communicationMethod: CommunicationMethod;
}

const schema = yup.object({
  firstName: yup.string(),
  lastName: yup.string(),
  middleName: yup.string(),
  mobileNumber: yup
    .string()
    .matches(REGEXPS.phoneRegExp, ErrorTexts.incerrectPhoneNumber),
  workNumber: yup.string(),
  birthdate: yup.string().matches(REGEXPS.dateRegExp, ErrorTexts.date),
  snils: yup
    .string()
    .matches(REGEXPS.snilsRegExp, ErrorTexts.incorrectSnilsNumber),

  email: yup.string().email(ErrorTexts.email),
});

const UserData: FC = () => {
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectors.auth.selectUserData);
  const managerName = userData.manager
    ? `${userData.manager.lastName} ${userData.manager.firstName} ${userData.manager.middleName}`
    : '';

  const [isEditable, setIsEditable] = useState(false);

  const defaultValues = {
    firstName: userData.firstName,
    lastName: userData.lastName,
    middleName: userData.middleName,
    mobileNumber: userData.mobileNumber,
    workNumber: userData.workNumber ? userData.workNumber : '',
    snils: userData.snils,
    address: userData.address ? userData.address : '',
    email: userData.email,
    birthdate: dayjs(userData.birthdate).format('DD.MM.YYYY'),
    communicationMethod: userData.communicationMethod as CommunicationMethod,
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitSuccessful, isValid, isDirty },
    getFieldState,
  } = useForm<FormData>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const isSuccessField = (field: keyof FormData) =>
    getFieldState(field, formState).isDirty && !errors[field];

  const onSubmit = handleSubmit(async (data) => {
    const neededData = getChangedValuesFromObjects(defaultValues, data);

    if (isEmptyObject(neededData)) {
      setIsEditable(false);
    } else {
      try {
        await dispatch(updateUserDataThunk(neededData)).unwrap();
        setIsEditable(false);
      } catch (rejectedValueOrSerializedError) {}
    }
  });

  const handleCancleButtonClick = () => reset(defaultValues);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [defaultValues]);

  return (
    <Root>
      <form onSubmit={onSubmit}>
        <Section>
          <SectionTitle>Мои данные</SectionTitle>
          <Inputs>
            <StyledInputWithLabel
              label="Фамилия"
              name="lastName"
              placeholder="Иванов"
              errorMessage={errors.lastName?.message}
              isSuccess={isSuccessField('lastName')}
              disabled={!isEditable}
              {...register('lastName')}
            />
            <StyledInputWithLabel
              label="Имя"
              name="firstName"
              placeholder="Иван"
              errorMessage={errors.firstName?.message}
              isSuccess={isSuccessField('firstName')}
              disabled={!isEditable}
              {...register('firstName')}
            />

            <StyledInputWithLabel
              label="Отчество"
              name="middleName"
              placeholder="Иванович"
              errorMessage={errors.middleName?.message}
              isSuccess={isSuccessField('middleName')}
              disabled={!isEditable}
              {...register('middleName')}
            />

            <StyledInputWithLabel
              label="Дата рождения"
              name="birthdate"
              placeholder="17.09.1968"
              mask="99.99.9999"
              errorMessage={errors.birthdate?.message}
              isSuccess={isSuccessField('birthdate')}
              disabled={!isEditable}
              {...register('birthdate')}
            />
            <StyledInputWithLabel
              label="Мобильный телефон"
              name="mobileNumber"
              type="tel"
              placeholder="+7 (903) 485 24-32"
              mask="+7 (999) 999-99-99"
              errorMessage={errors.mobileNumber?.message}
              isSuccess={isSuccessField('mobileNumber')}
              disabled={!isEditable}
              {...register('mobileNumber')}
            />
            <StyledInputWithLabel
              label="Рабочий телефон"
              name="workNumber"
              type="tel"
              placeholder="Рабочий телефон"
              errorMessage={errors.workNumber?.message}
              isSuccess={isSuccessField('workNumber')}
              disabled={!isEditable}
              {...register('workNumber')}
            />
            <StyledInputWithLabel
              label="СНИЛС"
              name="snils"
              placeholder="123-456-789-01"
              mask="999-999-999 99"
              errorMessage={errors.snils?.message}
              isSuccess={isSuccessField('snils')}
              disabled={!isEditable}
              {...register('snils')}
            />
            <StyledInputWithLabel
              label="Почтовый адрес"
              name="address"
              placeholder="Почтовый адрес"
              errorMessage={errors.address?.message}
              isSuccess={isSuccessField('address')}
              disabled={!isEditable}
              {...register('address')}
            />

            <StyledInputWithLabel
              label="E-mail"
              name="email"
              type="email"
              placeholder="E-mail"
              errorMessage={errors.email?.message}
              isSuccess={isSuccessField('email')}
              disabled={!isEditable}
              {...register('email')}
            />
          </Inputs>
        </Section>
        <Section>
          <SectionTitle>Мой консультант</SectionTitle>
          <StyledInputWithLabel
            label="ФИО консультанта"
            name="manager"
            placeholder="Ваш консультант"
            disabled
            readOnly
            value={managerName}
          />
          <CheckboxGroup>
            <CheckboxGroupTitle>Способ связи</CheckboxGroupTitle>
            <Checkboxes>
              <StyledCheckbox
                {...register('communicationMethod')}
                type="radio"
                name="communicationMethod"
                label="Электроная почта"
                value={CommunicationMethod.EMAIL}
                disabled={!isEditable}
              />
              <StyledCheckbox
                {...register('communicationMethod')}
                type="radio"
                name="communicationMethod"
                label="СМС"
                value={CommunicationMethod.SMS}
                disabled={!isEditable}
              />
              <StyledCheckbox
                {...register('communicationMethod')}
                type="radio"
                name="communicationMethod"
                label="WhatsApp / Viber"
                disabled={!isEditable}
                value={CommunicationMethod.SOCIAL_MEDIA}
              />
            </Checkboxes>
          </CheckboxGroup>
        </Section>
        <ButtonsWrapper>
          {!isEditable && (
            <StyledButton type="button" onClick={() => setIsEditable(true)}>
              Изменить
            </StyledButton>
          )}
          {isEditable && (
            <>
              <StyledButton disabled={!isDirty || !isValid}>
                Сохранить
              </StyledButton>
              <StyledButton
                theme="secondary"
                type="button"
                onClick={() => {
                  setIsEditable(false);
                  handleCancleButtonClick();
                }}
              >
                Отмена
              </StyledButton>
            </>
          )}
        </ButtonsWrapper>
      </form>
    </Root>
  );
};

const Root = styled.div``;

const Section = styled.div`
  margin-bottom: 48px;
  :last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.p`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black};
  margin-bottom: 32px;
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledInputWithLabel = styled(InputWithLabel)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 24px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 600px) {
    max-width: none;
    margin-right: 0;
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const CheckboxGroup = styled.div`
  margin-top: 32px;
`;

const CheckboxGroupTitle = styled.p`
  ${TYPOGRAPHY.body2Medium16};
  color: ${COLORS.black};
  margin-bottom: 16px;
`;

const Checkboxes = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-right: 16px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 600px) {
    margin-right: 0;
    margin-bottom: 18px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default UserData;
