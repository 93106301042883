import { ChangeAvatar } from '@/components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { selectors } from '@/store/ducks';
import { useAppSelector } from '@/store/hooks';
import React, { FC } from 'react';
import styled from 'styled-components';

interface UserAvatarProps {
  className?: string;
}

const UserAvatar: FC<UserAvatarProps> = ({ className }) => {
  const { firstName } = useAppSelector(selectors.auth.selectUserData);
  return (
    <Root className={className}>
      <Wrapper>
        <StyledChangeAvatar />
        <UserName>{firstName}</UserName>
      </Wrapper>
    </Root>
  );
};

const Root = styled.div`
  background-color: ${COLORS.white};
  border-radius: 16px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 24px;
`;

const StyledChangeAvatar = styled(ChangeAvatar)`
  margin-bottom: 16px;
`;

const UserName = styled.p`
  ${TYPOGRAPHY.headline2SemiBold22};
  color: ${COLORS.black};
  text-align: center;
`;

export default UserAvatar;
