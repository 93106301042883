import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import { TabData } from '@/types';
import { navigate } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

interface UserNavigationProps {
  data: TabData[];
  onToggle: React.Dispatch<React.SetStateAction<string>>;
  activeValue: string;
  className?: string;
}

const UserNavigation: FC<UserNavigationProps> = ({
  data,
  activeValue,
  onToggle,
  className,
}) => {
  return (
    <Root className={className}>
      {data.map((item, index) => {
        return (
          <Item key={index}>
            <ItemButton
              $isActive={activeValue === item.value}
              onClick={() => {
                navigate(`${LINKS.profile}?tab=${item.value}`);
                onToggle(item.value);
              }}
            >
              {item.text}
            </ItemButton>
          </Item>
        );
      })}
    </Root>
  );
};

const Root = styled.ul`
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: ${COLORS.white};
  border-radius: 16px;
  overflow: hidden;
`;

const Item = styled.li`
  margin-bottom: 12px;
  :last-child {
    margin-bottom: 0;
  }
`;

const ItemButton = styled.button<{ $isActive: boolean }>`
  position: relative;
  ${({ $isActive }) =>
    $isActive ? TYPOGRAPHY.body1SemiBold18 : TYPOGRAPHY.body1Regular18}
  color: ${({ $isActive }) =>
    $isActive ? COLORS.primaryDefault : COLORS.black};
  background-color: ${({ $isActive }) =>
    $isActive ? 'rgba(232, 241, 255, 0.5)' : ''};
  padding: 13px 20px;
  width: 100%;
  text-align: left;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 4px;
    height: 100%;
    background-color: ${({ $isActive }) =>
      $isActive ? COLORS.primaryDefault : 'transparent'};
  }
`;

export default UserNavigation;
