import dayjs from 'dayjs';

export function getChangedValuesFromObjects(
  defaultObject: { [key: string]: string },
  changedObject: { [key: string]: string }
) {
  let neededObject = {};
  for (const key in changedObject) {
    if (Object.prototype.hasOwnProperty.call(changedObject, key)) {
      const element = changedObject[key];
      if (defaultObject[key] !== element) {
        if (dayjs(element, 'DD.MM.YYYY').isValid()) {
          neededObject[key] = dayjs(element, 'DD.MM.YYYY').format();
        } else {
          neededObject[key] = element;
        }
      }
    }
  }
  return neededObject;
}
