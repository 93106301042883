import React, { FC } from 'react';
import { Services } from '@/components';
import { UserInfo } from '../UserInfo';

const Profile: FC = () => {
  return (
    <div>
      <UserInfo />
      <Services />
    </div>
  );
};

export default Profile;
