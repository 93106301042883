import React from 'react';
import { Router } from '@reach/router';
import { Profile } from '@/pageComponents/ProfilePage';
import { PrivateRoute, Seo } from '@/components';
import { ProfileLayout } from '@/layouts';
import retireds from '@/assets/images/retireds.png';
import { SeoData } from '@/types';

export default function ProfilePage() {
  const seoData: SeoData = {
    metaTitle: 'Социальный проект «Выплаты»',
    metaDescription: 'Социальный проект «Выплаты»',
    keywords: 'пенсия, деньги, выплата',
    sharedImage: {
      url: retireds,
    },
  };
  return (
    <ProfileLayout>
      <Seo data={seoData} />
      <Router>
        <PrivateRoute path="/profile" component={Profile} />
      </Router>
    </ProfileLayout>
  );
}
