import { Container } from '@/components';
import { COLORS } from '@/constants';
import { TabData } from '@/types';
import { useLocation, useParams } from '@reach/router';
import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  UserData,
  UserNavigation,
  UserPasswords,
  UserAvatar,
  UserAccumulations,
} from './components';
const queryString = require('query-string');

enum ActiveTabs {
  data = 'data',
  accumulation = 'accumulation',
  password = 'password',
}

const navigationData: TabData[] = [
  {
    text: 'Мои данные',
    value: ActiveTabs.data,
  },
  {
    text: 'Мои накопления',
    value: ActiveTabs.accumulation,
  },
  {
    text: 'Пароль',
    value: ActiveTabs.password,
  },
];

const UserInfo: FC = () => {
  const router = useLocation();
  const queryTab = queryString.parse(router.search);
  const [activeTab, setActiveTab] = useState<ActiveTabs>(
    queryTab?.tab || ActiveTabs.data
  );

  useEffect(() => {
    setActiveTab(queryTab?.tab || ActiveTabs.data);
  }, [router]);

  return (
    <Root>
      <StyledContainer>
        <Sidebar>
          <StyledUserAvatar />
          <StyledUserrNavigation
            data={navigationData}
            activeValue={activeTab}
            onToggle={setActiveTab}
          />
        </Sidebar>
        <Content>
          {activeTab === ActiveTabs.data && <UserData />}
          {activeTab === ActiveTabs.accumulation && <UserAccumulations />}
          {activeTab === ActiveTabs.password && <UserPasswords />}
        </Content>
      </StyledContainer>
    </Root>
  );
};

const Root = styled.section`
  background-color: ${COLORS.grayLighter};
`;

const StyledContainer = styled(Container)`
  padding-top: 48px;
  padding-bottom: 80px;
  display: flex;
  align-items: flex-start;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Sidebar = styled.div`
  width: 100%;
  max-width: 281px;
  margin-right: 24px;
  @media (max-width: 1024px) {
    max-width: none;
    margin-right: 0;
    margin-bottom: 32px;
  }
`;

const StyledUserAvatar = styled(UserAvatar)`
  margin-bottom: 24px;
`;

const StyledUserrNavigation = styled(UserNavigation)`
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export default UserInfo;
