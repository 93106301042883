import { AddFilebutton } from '@/components';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { selectors } from '@/store/ducks';
import { fetchMyContractsThunk } from '@/store/ducks/auth/thunks';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Percentage, Plus, Wallet } from '@/ui/iconComponents';
import currency from 'currency.js';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { PieChart, Pie, Cell } from 'recharts';
import dayjs from 'dayjs';

const colorsForChart = [
  COLORS.additionalGreen,
  COLORS.additionalYellow,
  COLORS.primaryDark,
  COLORS.additionalRed,
];

const pensionInsuranceName = 'Сведения о состоянии лицевого счета с Госуслуг';
const nonStatePensionAgreement =
  'Договор негосударственного пенсионного обеспечения';

const UserAccumulations: FC = () => {
  const dispatch = useAppDispatch();
  const contracts = useAppSelector(selectors.auth.selectContracts);
  console.log(contracts);

  const lastUpdateDay = () =>
    dayjs.max(contracts.map((item) => dayjs(item.createdAt))).format('LL');

  const getSumByContractName = (name: string) => {
    const contract = contracts.find((contract) => contract.title === name);

    return contract ? contract.sum : '0';
  };

  const getContractByName = (name: string) =>
    contracts.find((contract) => contract.title === name);

  const dataForChart = contracts.map((item) => ({
    name: item.title,
    value: +item.sum,
  }));

  const totalSum = contracts.reduce(
    (accumulator, currentValue) => accumulator + +currentValue.sum,
    0
  );
  console.log(totalSum);

  const formatedTotalSum = currency(totalSum, {
    precision: 2,
    separator: ' ',
    symbol: '₽',
    pattern: '# !',
    decimal: ',',
  }).format();

  useEffect(() => {
    dispatch(fetchMyContractsThunk());
  }, [dispatch]);

  return (
    <Root>
      <SectionTitle>Накопления</SectionTitle>
      <ContractsSection>
        <ContractsTotalBlock>
          <TotalSumCircleWrap>
            <PieChart width={190} height={190}>
              <Pie
                data={dataForChart}
                innerRadius={80}
                outerRadius={90}
                fill="#8884d8"
                paddingAngle={2}
                dataKey="value"
              >
                {dataForChart.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colorsForChart[index % colorsForChart.length]}
                  />
                ))}
              </Pie>
            </PieChart>
            <TotalSumCircleText>
              {contracts.length > 0 ? (
                <>
                  <TotalPieTextUpdate>Обновления</TotalPieTextUpdate>
                  <UpdateDate>{lastUpdateDay()}</UpdateDate>
                </>
              ) : (
                <EmptyPieText>
                  Нажмите на + <br /> Загрузите выписку с Госуслуг
                </EmptyPieText>
              )}
            </TotalSumCircleText>
          </TotalSumCircleWrap>
          <TotalSum>{formatedTotalSum}</TotalSum>
          <DescriptionText>Общая сумма ваших накоплений</DescriptionText>
        </ContractsTotalBlock>
        <Contract>
          <MoneyWithIcon>
            <IconWrapper>
              <Wallet color={COLORS.white} />
            </IconWrapper>
            <ContactSum>
              {currency(getSumByContractName(pensionInsuranceName), {
                precision: 0,
                separator: ' ',
                symbol: '₽',
                pattern: '# !',
                decimal: '.',
              }).format()}
            </ContactSum>
            {getContractByName(pensionInsuranceName) ? (
              <StyledAddFileButton
                fileName={pensionInsuranceName}
                idFile={getContractByName(pensionInsuranceName).id}
                isOnlyPdf={true}
              />
            ) : (
              <StyledAddFileButton
                fileName={pensionInsuranceName}
                isOnlyPdf={true}
              />
            )}
          </MoneyWithIcon>
          <ContractDescriptionText>
            {getContractByName(pensionInsuranceName) ? (
              <a
                href={getContractByName(pensionInsuranceName).pdfUrl}
                target="_blank"
              >
                {pensionInsuranceName}
              </a>
            ) : (
              pensionInsuranceName
            )}
          </ContractDescriptionText>
        </Contract>
        <Contract>
          <MoneyWithIcon>
            <IconWrapper $color={COLORS.additionalYellow}>
              <Percentage color={COLORS.white} />
            </IconWrapper>
            <ContactSum>
              {currency(getSumByContractName(nonStatePensionAgreement), {
                precision: 0,
                separator: ' ',
                symbol: '₽',
                pattern: '# !',
                decimal: '.',
              }).format()}
            </ContactSum>
            {getContractByName(nonStatePensionAgreement) ? (
              <StyledAddFileButton
                fileName={nonStatePensionAgreement}
                idFile={getContractByName(nonStatePensionAgreement).id}
              />
            ) : (
              <StyledAddFileButton fileName={nonStatePensionAgreement} />
            )}
          </MoneyWithIcon>
          <ContractDescriptionText>
            {getContractByName(nonStatePensionAgreement) ? (
              <a
                href={getContractByName(nonStatePensionAgreement).pdfUrl}
                target="_blank"
              >
                {nonStatePensionAgreement}
              </a>
            ) : (
              nonStatePensionAgreement
            )}
          </ContractDescriptionText>
        </Contract>
      </ContractsSection>
    </Root>
  );
};

const Root = styled.div``;

const SectionTitle = styled.p`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black};
  margin-bottom: 32px;
`;

const ContractsSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, minmax(176px, auto));
  gap: 16px 24px;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ContractCell = styled.div`
  background-color: ${COLORS.white};
  border-radius: 16px;
  @media (max-width: 768px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const ContractsTotalBlock = styled(ContractCell)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  grid-row: 1/-1;
  padding: 32px;
  @media (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }
`;

const TotalSumCircleWrap = styled.div`
  margin-bottom: 32px;
  align-self: center;
  position: relative;
  ::before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 180px;
    height: 180px;
    border-radius: 50%;
    border: 8px solid ${COLORS.grayLight};
  }
`;

const TotalSumCircleText = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 160px;
`;

const EmptyPieText = styled.p`
  ${TYPOGRAPHY.caption1Medium14};
  color: ${COLORS.gray};
  text-align: center;
`;

const TotalPieTextUpdate = styled(EmptyPieText)``;

const UpdateDate = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.black};
  text-align: center;
`;

const TotalSum = styled.p`
  ${TYPOGRAPHY.title3SemiBold32};
  color: ${COLORS.black};
  margin-bottom: 12px;
`;

const DescriptionText = styled.p`
  ${TYPOGRAPHY.body1Regular18};
  color: ${COLORS.grayDarker};
`;

const ContractDescriptionText = styled(DescriptionText)`
  a {
    color: ${COLORS.primaryDefault};
    :hover {
      text-decoration: underline;
    }
  }
  @media (max-width: 768px) {
    ${TYPOGRAPHY.body2Regular16}
  }
`;

const Contract = styled(ContractCell)`
  padding: 32px 24px;
`;

const MoneyWithIcon = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

const IconWrapper = styled.div<{ $color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border-radius: 4px;
  background-color: ${({ $color }) =>
    $color ? $color : COLORS.additionalGreen};
  margin-right: 16px;
`;

const ContactSum = styled.p`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black};

  @media (max-width: 374px) {
    ${TYPOGRAPHY.headline2SemiBold22};
  }
`;

const StyledAddFileButton = styled(AddFilebutton)`
  margin-left: auto;
`;

export default UserAccumulations;
