import { COLORS, LINKS, TYPOGRAPHY } from '@/constants';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { ErrorTexts } from '@/types';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { selectors } from '@/store/ducks';
import { resetPasswordThunk } from '@/store/ducks/auth/thunks';
import { Button, InputWithLabel, PasswordInput } from '@/ui/components';
import { navigate } from 'gatsby';

interface FormData {
  newPassword: string;
  passwordConfirmation: string;
}

const schema = yup
  .object({
    newPassword: yup
      .string()
      .min(6, ErrorTexts.lengthPassword)
      .required(ErrorTexts.required),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('newPassword'), null], ErrorTexts.confirmPassword)
      .required(ErrorTexts.required),
  })
  .required();

const UserPasswords: FC = () => {
  const dispatch = useAppDispatch();
  const { snils } = useAppSelector(selectors.auth.selectUserData);
  const [isEditable, setIsEditable] = useState(false);
  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitSuccessful, isValid, isDirty },
    getFieldState,
  } = useForm<FormData>({
    mode: 'onChange',

    resolver: yupResolver(schema),
  });

  const isSuccessField = (field: keyof FormData) =>
    getFieldState(field, formState).isDirty && !errors[field];

  const onSubmit = handleSubmit(async (data) => {
    const neededData = {
      snils: snils,
      newPassword: data.newPassword,
    };

    try {
      const result = await dispatch(resetPasswordThunk(neededData)).unwrap();
      if (result) {
        navigate(LINKS.singIn);
      }
    } catch (rejectedValueOrSerializedError) {}
  });
  return (
    <Root>
      <Section>
        <SectionTitle>Мой пароль</SectionTitle>
        <form onSubmit={onSubmit}>
          <Inputs>
            <StyledPasswordInput
              label="Новый пароль"
              placeholder="Новый пароль"
              errorMessage={errors.newPassword?.message}
              isSuccess={isSuccessField('newPassword')}
              disabled={!isEditable}
              {...register('newPassword')}
            />
            <StyledPasswordInput
              label="Подтвердить пароль"
              placeholder="Подтвердить пароль"
              errorMessage={errors.passwordConfirmation?.message}
              isSuccess={isSuccessField('passwordConfirmation')}
              disabled={!isEditable}
              {...register('passwordConfirmation')}
            />
          </Inputs>

          <ButtonsWrapper>
            {!isEditable && (
              <StyledButton type="button" onClick={() => setIsEditable(true)}>
                Изменить пароль
              </StyledButton>
            )}
            {isEditable && (
              <>
                <StyledButton disabled={!isDirty || !isValid}>
                  Сохранить
                </StyledButton>
                <StyledButton
                  theme="secondary"
                  type="button"
                  onClick={() => {
                    setIsEditable(false);
                    reset({ newPassword: '', passwordConfirmation: '' });
                  }}
                >
                  Отмена
                </StyledButton>
              </>
            )}
          </ButtonsWrapper>
        </form>
      </Section>
    </Root>
  );
};

const Root = styled.div``;

const Section = styled.div`
  margin-bottom: 48px;
  :last-child {
    margin-bottom: 0;
  }
`;

const SectionTitle = styled.p`
  ${TYPOGRAPHY.title4Medium28};
  color: ${COLORS.black};
  margin-bottom: 32px;
`;

const Inputs = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px 24px;
  margin-bottom: 32px;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledPasswordInput = styled(PasswordInput)`
  @media (max-width: 600px) {
    margin-bottom: 24px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  @media (max-width: 600px) {
    display: block;
  }
`;

const StyledButton = styled(Button)`
  margin-right: 24px;
  :last-child {
    margin-right: 0;
  }
  @media (max-width: 600px) {
    max-width: none;
    margin-right: 0;
    margin-bottom: 12px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default UserPasswords;
